import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/nord/nord_img1.png'
import img2 from '../../assets/images/nord/nord_img2.png'
import img3 from '../../assets/images/nord/nord_img3.png'
import img4 from '../../assets/images/nord/nord_img4.png'
import img5 from '../../assets/images/nord/nord_img5.png'
import img6 from '../../assets/images/nord/nord_img6.png'
import img7 from '../../assets/images/nord/nord_img7.png'
import img8 from '../../assets/images/nord/nord_img8.png'
import img9 from '../../assets/images/nord/nord_img9.png'
import img10 from '../../assets/images/nord/nord_img10.png'
import img11 from '../../assets/images/nord/nord_img11.png'
import img12 from '../../assets/images/nord/nord_img12.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterImage,
  SectionFullImage,
  SectionImagesGrid,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineL,
  HeadlineM,
  TitleM,
  SubtitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Grafično oblikovanje',
    'Spletne strani',
    'Znamčenje',
    'Oglaševanje na družbenih omrežjih',
    'Grafično oblikovanje',
    'Spletne strani',
    'Znamčenje',
    'Oglaševanje na družbenih omrežjih',
    'Grafično oblikovanje',
    'Spletne strani',
    'Znamčenje',
    'Oglaševanje na družbenih omrežjih',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Nord Hard Seltzer"
        description="Nord Hard Seltzer je en naših lastnih projektov, kjer skrbimo za vse marketinške aktivnosti. Od začetnega izbora imena znamke, do vseh promocijskih aktivnosti."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Nord Hard Seltzer" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>Nord Drinks</ParagraphM>
            <HeadlineM color={colors.purple}>Nord Hard Seltzer</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Trgu predstaviti <b>prvi</b> slovenski hard seltzer.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Nord Hard Seltzer je en naših lastnih projektov, kjer skrbimo za vse marketinške
              aktivnosti. Od začetnega izbora imena znamke, do vseh promocijskih aktivnosti.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding reduce-bottom-padding">
            <Content>
              {/* Example Title */}
              <h2>Kaj sploh je hard seltzer?</h2>

              {/* Example Paragraph */}
              <p>
                Gre za novo kategorijo alkoholnih pijač, ki po okusu najbolj spominja na gazirano
                vodo z okusom. Je brez dodanega sladkorja, je osvežilna in vsebuje le štiri
                sestavine - vodo, alkohol, CO2 in naravne arome.
              </p>

              {/* Example Title */}
              <h2>Zakaj Nord?</h2>

              {/* Example Paragraph */}
              <p>
                Ko smo izbirali ime za produkt smo želeli, da se sliši osvežilno. Pomembno nam je
                bilo, da si je ime lahko zapomniti in ni vprašanj kako ga izgovoriti v kateremkoli
                evropskem jeziku. Nenazadnje smo si predstavljali, kako bodo kupci v barih to pijačo
                naročili in ‘enega Norda, prosim’ se je slišalo kot tisto pravo.
              </p>

              {/* Example Image */}
              <img src={img2} alt="Nord Hard Seltzer" />

              {/* Example Title */}
              <h2>Znamčenje</h2>

              {/* Example Paragraph */}
              <p>
                Tako kot sam produkt, je minimalistična tudi njegova podoba. Da bi produkt na
                policah čimbolj izstopal, smo etiketo na pločevinki spustili nižje kot je običajno.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Nord Hard Seltzer" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Vstop na trg</h2>

              {/* Example Paragraph */}
              <p>
                Nord Hard Seltzer je bil pripravljen na vstop na trg v oktobru 2020, teden preden
                smo vstopili v lockdown, ki je trajal pol leta. V tem času so bili bari, glavni
                prodajni kanali za to pijačo zaprti. Zato smo morali biti inovativni in do kupcev
                priti s prodajo preko spleta, hkrati pa smo poskušali trgovce prepričati, da Norda
                dodajo v ponudbo, čeprav gre za novonastalo znamko.
              </p>
            </Content>
          </SectionRichText>

          {/* CounterImages Section - Conditionally visible */}
          <SectionCounterImage>
            <div className="image">
              <img src={img4} alt="Nord Hard Seltzer" />
            </div>
            <Square background={colors.purple} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                200+
              </HeadlineL>
              <SubtitleM color={colors.white}>Prodajnih lokacij</SubtitleM>
            </Square>
          </SectionCounterImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Promocija</h2>

              {/* Example Paragraph */}
              <p>
                Z Nordom smo prisotni na družbenih omrežjih Facebook in Instagram. V poletju 2021
                smo izvedli promocijsko kampanjo To poletje bo Nord, o kateri si lahko več preberete
                tudi {''}
                <a href="https://mintandsquare.com/project/to-poletje-bo-nord" target="_blank">
                  tukaj
                </a>
                .
              </p>
              {/* Example Title */}
              <h2>Dogodek za rojstni dan</h2>

              {/* Example Paragraph */}
              <p>Ob prvem Nord rojstnem dnevu smo organizirali dogodek v baru Zorica.</p>
            </Content>
          </SectionRichText>

          {/* ImagesGrid Section - Conditionally visible */}
          <SectionImagesGrid>
            <div className="image">
              <img src={img5} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img6} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img7} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img8} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img9} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img10} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img11} alt="Nord Hard Seltzer" />
            </div>
            <div className="image">
              <img src={img12} alt="Nord Hard Seltzer" />
            </div>
          </SectionImagesGrid>
        </div>

        {/* RichText Section - Conditionally visible */}
        <SectionRichText>
          <Content>
            {/* Example Title */}
            <h2>...in več</h2>

            {/* Example Paragraph */}
            <p>
              Nord Hard Seltzer je iz stranskega projekta prerasel v čisto svoj velik projekt, o
              katerem bi lahko pisali v nedogled. Če imate glede tega projekta kakšno vprašanje se
              nam oglasite.
            </p>
          </Content>
        </SectionRichText>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          {/* <Link to="/project/previous-project"> */}
          <ParagraphL className="disabled">← Prejšnji projekt </ParagraphL>
          {/* </Link> */}
          <Link to="/project/butik-festival">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
